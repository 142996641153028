import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Supplier, Page } from "@/types";

type SupplierState = {
  supplierPage: Page<Supplier>;
};

const supplier: Module<SupplierState, unknown> = {
  namespaced: true,
  state: () => ({
    supplierPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getSupplier: (state) => (supplierId: string) =>
      state.supplierPage.docs.find((c) => c._id === supplierId),
    supplierPage: (state) => state.supplierPage,
  },
  mutations: {
    ADD_SUPPLIER: (state, _supplier) => {
      let idx = -1;
      state.supplierPage.docs.map((a, i) => {
        if (a._id === _supplier._id) idx = i;
      });
      if (idx === -1) state.supplierPage.docs.push(_supplier);
      else Vue.set(state.supplierPage.docs, idx, _supplier);
    },
    SET_SUPPLIER_PAGE: (state, list) => {
      state.supplierPage = list;
    },
  },
  actions: {
    fetchSupplier(context, params = "") {
      api
        .get(`/v1/supplier${params}`)
        .then((response) => {
          context.commit("ADD_SUPPLIER", response.data.supplier);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchSupplierList(context, params = "") {
      return await api
        .get(`/v1/supplier${params}`)
        .then((response) => {
          context.commit("SET_SUPPLIER_PAGE", response.data.supplierPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createSupplier(context, payload) {
      return await api
        .post(`/v1/supplier`, payload)
        .then((response) => {
          context.commit("ADD_SUPPLIER", response.data.supplier);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Supplier created",
            },
            { root: true }
          );
          return response.data.supplier;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateSupplier(context, data: { id: string; supplier: Supplier }) {
      return await api
        .put(`/v1/supplier/${data.id}`, data.supplier)
        .then((response) => {
          context.commit("ADD_SUPPLIER", response.data.supplier);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Supplier updated",
            },
            { root: true }
          );
          return response.data.supplier;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default supplier;
