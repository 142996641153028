








































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import supplierStoreModule from "@/store/modules/supplier";
import supplierCategoryStoreModule from "@/store/modules/supplierCategory";
import { Business, Role, Supplier } from "@/types";
import SupplierForm from "./SupplierForm.vue";

const { mapActions: supplierActions, mapGetters: supplierGetters } =
  createNamespacedHelpers("SUPPLIER_LIST");

const { mapActions: supplierCategoryActions } = createNamespacedHelpers(
  "SUPPLIER_CATEGORIES"
);
const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: { SupplierForm },
  name: "SupplierList",
  data: () => ({
    supplier: undefined as undefined | Supplier,
    selectedCategory: "",
    showSupplierDialog: false,
    showDialog: false,
    apiUrl: VUE_APP_API_URL,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "fullName",
      },
      { text: "Category", value: "category" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    categoryDialog: false,
    categoryName: "",
    categoryRules: [(v: string) => !!v || "Category Name field is required"],
  }),
  watch: {
    role() {
      this.fetchSuppliers();
    },
    options: {
      handler() {
        this.fetchSuppliers();
      },
      deep: true,
    },
  },
  computed: {
    ...supplierGetters(["supplierPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...supplierActions([
      "fetchSupplierList",
      "updateSupplier",
      "deleteSupplier",
    ]),
    ...supplierCategoryActions(["createSupplierCategory"]),
    viewSupplier(supplier: Supplier) {
      this.supplier = supplier;
    },
    fetchSuppliers() {
      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)._id
        }&page=${this.options.page || 1}`;
        this.fetchSupplierList(params);
      }
    },
    closeModal() {
      this.showSupplierDialog = false;
      this.fetchSuppliers();
    },
    validateCategory() {
      const valid = (
        this.$refs.categoryForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      this.createSupplierCategory({ name: this.categoryName }).then((cat) => {
        if (cat) this.categoryDialog = false;
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SUPPLIER_LIST")) {
      this.$store.registerModule("SUPPLIER_LIST", supplierStoreModule);
    }
    if (!this.$store.hasModule("SUPPLIER_CATEGORIES")) {
      this.$store.registerModule(
        "SUPPLIER_CATEGORIES",
        supplierCategoryStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SUPPLIER_LIST");
    this.$store.unregisterModule("SUPPLIER_CATEGORIES");
  },
});
