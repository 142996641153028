


















































































































































































import Vue from "vue";
import Uploader from "@/components/fragments/uploader.vue";

export default Vue.extend<any, any, any, any>({
  name: "EditVariant",
  components: { Uploader },
  props: {
    variant: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    images: [] as Blob[],
  }),
  watch: {
    variant() {
      this.images = [];
    },
  },
  methods: {
    close() {
      this.$emit("cancel");
    },
    updateVariant() {
      const data = { variant: this.variant, files: this.images };
      this.$emit("update", data);
    },
  },
});
