import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { SupplierCategory, Page } from "@/types";

type SupplierCategoryState = {
  supplierCategoryPage: Page<SupplierCategory>;
};

const supplierCategory: Module<SupplierCategoryState, unknown> = {
  namespaced: true,
  state: () => ({
    supplierCategoryPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getSupplierCategory: (state) => (supplierCategoryId: string) =>
      state.supplierCategoryPage.docs.find((c) => c._id === supplierCategoryId),
    supplierCategoryPage: (state) => state.supplierCategoryPage,
  },
  mutations: {
    ADD_SUPPLIER_CATEGORY: (state, _supplierCategory) => {
      let idx = -1;
      state.supplierCategoryPage.docs.map((a, i) => {
        if (a._id === _supplierCategory._id) idx = i;
      });
      if (idx === -1) state.supplierCategoryPage.docs.push(_supplierCategory);
      else Vue.set(state.supplierCategoryPage.docs, idx, _supplierCategory);
    },
    SET_SUPPLIER_CATEGORY_PAGE: (state, list) => {
      state.supplierCategoryPage = list;
    },
    REMOVE_SUPPLIER_CATEGORY(state, supplierCategory) {
      let idx = -1;
      state.supplierCategoryPage.docs.map((a, i) => {
        if (a._id === supplierCategory._id) idx = i;
      });
      if (idx > -1) state.supplierCategoryPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchSupplierCategory(context, params = "") {
      api
        .get(`/v1/supplier-category${params}`)
        .then((response) => {
          context.commit(
            "ADD_SUPPLIER_CATEGORY",
            response.data.supplierCategory
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchSupplierCategoryList(context, params = "") {
      return await api
        .get(`/v1/supplier-category${params}`)
        .then((response) => {
          context.commit(
            "SET_SUPPLIER_CATEGORY_PAGE",
            response.data.supplierCategoryPage
          );
          return response.data.supplierCategoryPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createSupplierCategory(context, payload) {
      return await api
        .post(`/v1/supplier-category`, payload)
        .then((response) => {
          context.commit(
            "ADD_SUPPLIER_CATEGORY",
            response.data.supplierCategory
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "SupplierCategory created",
            },
            { root: true }
          );
          return response.data.supplierCategory;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateSupplierCategory(
      context,
      data: { id: string; supplierCategory: SupplierCategory }
    ) {
      api
        .put(`/v1/supplier-category/${data.id}`, data.supplierCategory)
        .then((response) => {
          context.commit(
            "ADD_SUPPLIER_CATEGORY",
            response.data.supplierCategory
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "SupplierCategory updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteSupplierCategory(context, id) {
      api
        .delete(`/v1/supplier-category${id}`)
        .then((response) => {
          context.commit(
            "REMOVE_SUPPLIER_CATEGORY",
            response.data.supplierCategory
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "SupplierCategory deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default supplierCategory;
