




































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import CategoryStoreModule from "@/store/modules/productCategory";
import { Business, InventoryCategory, Role } from "@/types";

const { mapGetters: categoryGetters, mapActions: categoryActions } =
  createNamespacedHelpers("INVENTORY_CATEGORY");

export default Vue.extend<any, any, any, any>({
  name: "ProductCategoryList",
  data: () => ({
    addDialog: false,
    deleteDialog: false,
    productCategory: undefined as undefined | InventoryCategory,
    category: {
      category_name: "",
      category_description: "",
      parent: "" as string | InventoryCategory,
    },
    headers: [
      { text: "Catgeory Name", value: "category_name", sortable: false },
      { text: "No. of Products", value: "products", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    options: {} as { page: number },
  }),
  computed: {
    ...categoryGetters({
      _categories: "_categoryPage",
      categories: "categoryPage",
    }),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    ...categoryActions([
      "fetchCategoryList",
      "createCategory",
      "updateCategory",
      "deleteCategory",
    ]),
    fetchCategories() {
      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)._id
        }&page=${this.options.page || 1}`;
        this.fetchCategoryList(params);
      }
    },
    editInit(item: InventoryCategory) {
      if (item) {
        this.productCategory = item;
        this.addDialog = true;
        this.category = {
          category_name: item.category_name,
          category_description: item.category_description,
          parent: item.parent,
        };
      }
    },
    deleteInit(item: InventoryCategory) {
      if (item) {
        this.productCategory = item;
        this.deleteDialog = true;
      }
    },
    resetForm() {
      this.category = {
        category_description: "",
        category_name: "",
        parent: "",
      };
    },
    addCategory() {
      if (this.productCategory) {
        this.updateCategory({
          id: this.productCategory._id,
          data: this.category,
        }).then(() => {
          this.resetForm();
          this.addDialog = false;
          this.fetchCategories();
          this.productCategory = undefined;
        });
      }

      if (!this.productCategory) {
        this.createCategory({
          business: (this.role.business as Business)._id,
          category_name: this.category.category_name,
          category_description: this.category.category_description,
          parent: this.category.parent,
        }).then(() => {
          this.resetForm();
          this.addDialog = false;
          this.fetchCategories();
        });
      }
    },
    dropCategory(categoryId: string) {
      this.deleteCategory(categoryId).then(() => {
        this.productCategory = undefined;
        this.deleteDialog = false;
        this.fetchCategories();
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("INVENTORY_CATEGORY")) {
      this.$store.registerModule("INVENTORY_CATEGORY", CategoryStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("INVENTORY_CATEGORY");
  },
});
