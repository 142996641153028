













































































































































































































































































































































































































































































































































































































import Vue from "vue";
import uploader from "@/components/fragments/uploader.vue";
import productInventoryModule from "@/store/modules/productInventory";
import ProductOptionsModule from "@/store/modules/productOptions";
import { createNamespacedHelpers } from "vuex";
import { Role, Business, InventoryProduct, InventoryOptions } from "@/types";
import productCategoryModule from "@/store/modules/productCategory";
import { VueEditor } from "vue2-editor";

const { mapActions: inventoryProductActions } = createNamespacedHelpers(
  "INVENTORY_PRODUCT_ADD"
);

const { mapActions: categoryActions, mapGetters: categoryGetters } =
  createNamespacedHelpers("INV_CATEGORY");

const {
  mapActions: inventoryOptionActions,
  mapGetters: inventoryOptionGetters,
} = createNamespacedHelpers("INVENTORY_OPTIONS");

const rules = {
  title: [(v: any) => !!v || "Product Title is required"],
  short_desc: [
    (v: any) => !!v || "Product Short Description is required",
    (v: any) =>
      (v || "").length <= 200 ||
      "Product Short Description shouldn`t be lengthy",
  ],
};

export default Vue.extend<any, any, any, any>({
  name: "AddProduct",
  components: { uploader },
  data: () => ({
    addOptionDialog: false,
    updateOptionDialog: false,
    panel: [0, 1, 2],
    isLoading: false,
    selectedOption: undefined as undefined | InventoryOptions,
    rules: rules,
    product_type_options: [
      {
        title: "Simple Product",
        value: "simple",
        desc: "Create a simple Product",
      },
      {
        title: "Variant Product",
        value: "variant",
        desc: "Create a Variant Product",
      },
    ],
    newProduct: {
      product_type: "",
      title: "",
      in_store: false,
      short_description: "",
      description: "",
      images: [],
      brand: "",
      manufacturer: "",
      weight: "",
      option_set: [
        {
          option_name_id: "",
          option_values: [],
        },
      ],
      variants: [
        {
          images: [],
          pricing: {
            supply_price: 0,
            retail_price: 0,
            last_price: 0,
          },
          stock: {
            monitor_stock: true,
            stock_position: 0,
          },
          options: [
            {
              option_name_id: "",
              option_value_id: "",
            },
          ],
        },
      ],
    },
    newOption: {
      option_name: "",
      option_values: "",
    },
  }),
  watch: {
    role() {
      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)._id
        }&page=1`;

        this.fetchProductList(params);
        this.fetchProductOptions();
        this.fetchProductCategories();
      }
    },
    isTypeSelected() {
      const { product_type } = this.newProduct;
      if (product_type == "simple") {
        this.newProduct.option_set = [];
        this.newProduct.variants = [
          {
            images: [],
            stock: {
              stock_position: 0,
              monitor_stock: true,
            },
            pricing: {
              retail_price: 0,
              supply_price: 0,
              last_price: 0,
            },
            options: [
              {
                option_name_id: "",
                option_value_id: "",
              },
            ],
          },
        ];
      } else {
        this.newProduct.variants = [];
        this.newProduct.option_set = [
          {
            option_name_id: "",
            option_values: [],
          },
        ];
      }
    },
  },
  computed: {
    ...inventoryOptionGetters({ _productOptions: "productOptions" }),
    ...categoryGetters(["categoryPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    categories(): any[] {
      return this.categoryPage?.docs || [];
    },
    isTypeSelected(): boolean {
      const { product_type } = this.newProduct;
      if (product_type) {
        return true;
      }
      return false;
    },
    isSimple(): boolean {
      const { product_type } = this.newProduct;
      if (product_type == "simple") {
        return true;
      }
      return false;
    },
    productOptionRules(): any[] {
      if (this.isTypeSelected && !this.isSimple) {
        return [(v: any) => !!v || "Product Option is required!"];
      }
      return [];
    },
    productOptions(): InventoryOptions[] {
      return this._productOptions;
    },
    combination_options(): any[] {
      const { option_set } = this.newProduct;
      if (!this.isSimple) {
        const filters = Object.values(option_set).map(
          (f: any) => f.option_name_id
        );
        const values = Object.values(option_set)
          .map((f: any) => f.option_values)
          .flat(1);

        if (
          !this.productOptions &&
          Object.values(this.productOptions).length == 0
        ) {
          return [];
        }

        const allowed_options = Object.values(this.productOptions || []).filter(
          (item: any) => filters.includes(item._id)
        ) as any;

        return Object.values(allowed_options).map((item: any) => {
          const item_values = Object.values(item.option_values).filter(
            (v: any) => values.includes(v._id)
          );
          return { ...item, option_values: item_values };
        }) as any;
      }
      return [];
    },
    possible_variants(): number {
      if (this.combination_options.length > 0) {
        return this.combination_options
          .map((v: any) => v.option_values.length)
          .reduce((p, c) => p * c);
      }
      return 0;
    },
    isInstore() {
      return this.$route.query.inStore === "true";
    },
  },
  mounted() {
    if (this.role) {
      const params = `?businessId=${
        (this.role.business as Business)._id
      }&page=1`;

      this.fetchProductList(params);
      this.fetchProductOptions();
      this.fetchProductCategories();
    }
  },
  methods: {
    ...inventoryProductActions([
      "createProduct",
      "fetchProductList",
      "updateImage",
      "updateVariantImage",
    ]),
    ...inventoryOptionActions(["createOption", "fetchOptions", "updateOption"]),
    ...categoryActions(["fetchCategoryList"]),
    fetchProductCategories() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)._id}`;
        this.fetchCategoryList(params);
      }
    },
    fetchProductOptions() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)._id}`;
        this.fetchOptions(params);
      }
    },
    cancel() {
      this.$emit("cancel-add");
      //   this.resetForm();
    },
    addOptionset() {
      this.newProduct.option_set.push({
        option_name_id: "",
        option_values: [],
      });
    },
    removeOptionset(index: number) {
      this.newProduct.option_set.splice(index, 1);
    },
    initEditOption(option: any) {
      this.selectedOption = option;
      this.updateOptionDialog = true;
      if (this.selectedOption) {
        this.newOption = {
          option_name: this.selectedOption.option_name,
          option_values: "",
        };
      }
    },
    cancelOption() {
      this.newOption = {
        option_name: "",
        option_values: "",
      };
      this.addOptionDialog = false;
      this.updateOptionDialog = false;
      this.selectedOption = undefined;
    },
    createInventoryOption() {
      const data = {
        businessId: (this.role.business as Business)?._id,
        ...this.newOption,
      };
      this.createOption({ ...data }).then(() => {
        this.cancelOption();
        this.fetchProductOptions();
      });
    },
    editInventoryOption() {
      if (this.selectedOption) {
        this.updateOption({
          id: this.selectedOption._id,
          data: this.newOption,
        }).then(() => {
          this.cancelOption();
          this.fetchProductOptions();
        });
      }
    },
    addOptionField(v_index: number) {
      if (
        this.newProduct.variants[v_index].options.length <
        this.combination_options.length
      ) {
        this.newProduct.variants[v_index].options.push({
          option_name_id: "",
          option_value_id: "",
        });
      }
    },
    removeOptionField(v_index: number, opt_index: number) {
      this.newProduct.variants[v_index].options.splice(opt_index, 1);
    },
    addVariant() {
      if (this.newProduct.variants.length < this.possible_variants) {
        this.newProduct.variants.push({
          images: [],
          pricing: {
            supply_price: 0,
            retail_price: 0,
            last_price: 0,
          },
          stock: {
            monitor_stock: true,
            stock_position: 0,
          },
          options: [
            {
              option_name_id: "",
              option_value_id: "",
            },
          ],
        });
      }
    },
    removeVariant(index: number) {
      this.newProduct.variants.splice(index, 1);
    },
    createInventoryProduct() {
      this.isLoading = true;
      if (this.isSimple) {
        const { option_set, brand, manufacturer, weight, images, ...product } =
          this.newProduct;
        let attributes: { attr_name: string; attr_value: string }[] = [];

        if (brand !== "") {
          attributes.push({ attr_name: "brand", attr_value: brand });
        }

        if (manufacturer !== "") {
          attributes.push({
            attr_name: "manufacturer",
            attr_value: manufacturer,
          });
        }

        if (weight !== "") {
          attributes.push({ attr_name: "weight", attr_value: weight });
        }

        let data = {
          businessId: (this.role.business as Business)?._id,
          attributes,
          ...product,
        };
        this.createProduct({ ...data, ...{ in_store: this.isInstore } })
          .then((product: InventoryProduct) => {
            if (product) {
              this.cancel();
            }

            if (product && images.length > 0) {
              let formData = new FormData();
              images.forEach((image) => formData.append("files", image));
              this.updateImage({ id: product._id, data: formData });
            }
          })
          .finally(() => (this.isLoading = false));
      }

      if (!this.isSimple) {
        const files: any[] = [];
        const { brand, manufacturer, weight, images, variants, ...product } =
          this.newProduct;
        let attributes: { attr_name: string; attr_value: string }[] = [];
        Object.values(variants).forEach((v: any) => {
          files.push(v.images);
        });

        const productVariants = Object.values(variants).map((v: any) => {
          const { images: variantImages, ...rest } = v;
          return { ...rest };
        });

        if (brand !== "") {
          attributes.push({ attr_name: "brand", attr_value: brand });
        }

        if (manufacturer !== "") {
          attributes.push({
            attr_name: "manufacturer",
            attr_value: manufacturer,
          });
        }

        if (weight !== "") {
          attributes.push({ attr_name: "weight", attr_value: weight });
        }

        let data = {
          businessId: (this.role.business as Business)?._id,
          attributes,
          variants: productVariants,
          ...product,
        };
        this.createProduct({ ...data })
          .then(async (product: InventoryProduct) => {
            if (product && files.length > 0) {
              for (let i = 0; i < product.variants.length; i++) {
                const v = product.variants[i];
                let formData = new FormData();
                const vimages = files[i];
                vimages.forEach((image: string | Blob) =>
                  formData.append("files", image)
                );
                await this.updateVariantImage({ id: v._id, data: formData });
              }
            }
            if (product && images.length > 0) {
              let formData = new FormData();
              images.forEach((image) => formData.append("files", image));
              await this.updateImage({ id: product._id, data: formData });
            }
            if (product) {
              this.cancel();
            }
          })
          .finally(() => (this.isLoading = false));
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("INVENTORY_PRODUCT_ADD")) {
      this.$store.registerModule(
        "INVENTORY_PRODUCT_ADD",
        productInventoryModule
      );
    }
    if (!this.$store.hasModule("INV_CATEGORY")) {
      this.$store.registerModule("INV_CATEGORY", productCategoryModule);
    }

    if (!this.$store.hasModule("INVENTORY_OPTIONS")) {
      this.$store.registerModule("INVENTORY_OPTIONS", ProductOptionsModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("INVENTORY_PRODUCT_ADD");
    this.$store.unregisterModule("INVENTORY_OPTIONS");
    this.$store.unregisterModule("INV_CATEGORY");
  },
});
