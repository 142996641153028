







import Vue from "vue";
import CreateProduct from "@/components/inventory/SaleProductAdd.vue";
import AddProduct from "@/components/inventory/AddProduct.vue";

export default Vue.extend<any, any, any, any>({
  name: "NewProduct",
  components: { AddProduct },
  methods: {
    goToProducts() {
      this.$router.push("/products");
    },
  },
});
