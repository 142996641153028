import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { SaleProduct, Page } from "@/types";

type SaleProductState = {
  saleProductPage: Page<SaleProduct>;
};

const saleProduct: Module<SaleProductState, unknown> = {
  namespaced: true,
  state: () => ({
    saleProductPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getSaleProduct: (state) => (saleProductId: string) =>
      state.saleProductPage.docs.find((s) => s._id === saleProductId),
    saleProductPage: (state) => state.saleProductPage,
  },
  mutations: {
    ADD_SALE_PRODUCT: (state, _saleProduct) => {
      let idx = -1;
      state.saleProductPage.docs.map((a, i) => {
        if (a._id === _saleProduct._id) idx = i;
      });
      if (idx === -1) state.saleProductPage.docs.push(_saleProduct);
      else Vue.set(state.saleProductPage.docs, idx, _saleProduct);
    },
    SET_SALE_PRODUCT_PAGE: (state, list) => {
      state.saleProductPage = list;
    },
    REMOVE_SALE_PRODUCT(state, saleProduct) {
      let idx = -1;
      state.saleProductPage.docs.map((a, i) => {
        if (a._id === saleProduct._id) idx = i;
      });
      if (idx > -1) state.saleProductPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchSaleProduct(context, params = "") {
      api
        .get(`/v1/sale-product${params}`)
        .then((response) => {
          context.commit("ADD_SALE_PRODUCT", response.data.saleProduct);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchSaleProductList(context, params = "") {
      return await api
        .get(`/v1/sale-product${params}`)
        .then((response) => {
          context.commit(
            "SET_SALE_PRODUCT_PAGE",
            response.data.saleProductPage
          );
          return response.data.saleProductPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createSaleProduct(context, payload) {
      return await api
        .post(`/v1/sale-product`, payload)
        .then((response) => {
          context.commit("ADD_SALE_PRODUCT", response.data.saleProduct);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Product created",
            },
            { root: true }
          );
          return response.data.saleProduct;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateSaleProduct(
      context,
      data: { id: string; saleProduct: SaleProduct }
    ) {
      return await api
        .put(`/v1/sale-product/${data.id}`, data.saleProduct)
        .then((response) => {
          context.commit("ADD_SALE_PRODUCT", response.data.saleProduct);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Product updated",
            },
            { root: true }
          );
          return response.data.saleProduct;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteSaleProduct(context, id) {
      api
        .delete(`/v1/sale-product${id}`)
        .then((response) => {
          context.commit("REMOVE_SALE_PRODUCT", response.data.saleProduct);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Product deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async uploadSaleProductImage(
      context,
      data: { id: string; saleProduct: SaleProduct }
    ) {
      return await api
        .put(`/v1/sale-product/image/${data.id}`, data.saleProduct)
        .then((response) => {
          context.commit("ADD_SALE_PRODUCT", response.data.saleProduct);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Proudct updated",
            },
            { root: true }
          );
          return response.data.saleProduct;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default saleProduct;
