import { InventoryCategory, Page } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

const actions = {
  add_category: "ADD_CATEGORY",
  set_category_list: "SET_CATEGORY_LIST",
  remove_category: "REMOVE_CATEGORY",
};

type ProductCategoryState = {
  categoryPage: Page<InventoryCategory>;
};

const productCategory: Module<ProductCategoryState, unknown> = {
  namespaced: true,
  state: () => ({
    categoryPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  mutations: {
    [actions.add_category](state, _category) {
      if (
        state.categoryPage.docs.filter((c) => c._id === _category._id).length >
        0
      ) {
        state.categoryPage.docs = state.categoryPage.docs.map(
          (item: any, index: number) =>
            item._id === _category._id ? _category : item
        );
      } else {
        state.categoryPage.docs.push(_category);
      }
    },
    [actions.set_category_list](state, _categories) {
      state.categoryPage = _categories;
    },
    [actions.remove_category](state, _category) {
      state.categoryPage.docs = state.categoryPage.docs.filter(
        (item) => item._id !== _category._id
      );
    },
  },
  getters: {
    categoryPage: (state) => state.categoryPage,
    _categoryPage: (state) => {
      return state.categoryPage.docs.map((category) => {
        if (category.parent) {
          category.isParent = false;
          return category;
        } else {
          category.isParent = true;
          return category;
        }
      });
    },
  },
  actions: {
    async createCategory(context, payload) {
      return await api
        .post("/v1/product/category", payload)
        .then((response) => {
          context.commit(actions.add_category, response.data.result);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Category Created",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    async fetchCategoryList(context, params = "") {
      return await api
        .get(`/v1/product/category${params}`)
        .then((response) => {
          context.commit(actions.set_category_list, response.data.categoryPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateCategory(
      context,
      payload: {
        id: string;
        data: {
          category_name: string;
          category_description: string;
          parent: string;
        };
      }
    ) {
      return await api
        .put(`/v1/product/category/${payload.id}`, payload.data)
        .then((response) => {
          context.commit(actions.add_category, response.data.category);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Category Updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteCategory(context, id: string) {
      return await api
        .delete(`/v1/product/category/${id}`, {
          headers: { "vendor-module": "products.Product Category" },
        })
        .then((response) => {
          context.commit(actions.remove_category, response.data.category);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Category Deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default productCategory;
