


















































































































































import Vue from "vue";

import productOptionsStore from "@/store/modules/productOptions";
import { createNamespacedHelpers } from "vuex";
import { Business, InventoryOptions, Role } from "@/types";

const { mapActions: optionActions, mapGetters: optionGetters } =
  createNamespacedHelpers("INVENTORY_OPTIONS");

/* eslint-disable @typescript-eslint/no-explicit-any */
export default Vue.extend<any, any, any, any>({
  data: () => ({
    deleteOptionDilaog: false,
    editOptionDialog: false,
    deleteOptionValDialog: false,
    selectOptionValue: undefined as undefined | { _id: string; value: string },
    productOption: undefined as undefined | InventoryOptions,
    editOption: {
      option_name: "",
      option_values: "",
    },
  }),
  computed: {
    ...optionGetters(["productOptions"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  created() {
    this.getProductOptions();
  },
  methods: {
    ...optionActions([
      "fetchOptions",
      "deleteOption",
      "createOption",
      "updateOption",
      "deleteOptionValue",
    ]),
    getProductOptions() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)._id}`;
        this.fetchOptions(params);
      }
    },
    addNewInit() {
      this.productOption = undefined;
      this.editOptionDialog = true;
    },
    dropOptionInit(item: InventoryOptions) {
      if (item) {
        this.productOption = item;
        this.deleteOptionDilaog = true;
      }
    },
    dropOption(id: string) {
      this.deleteOption(id).then(() => {
        this.deleteOptionDilaog = false;
        this.productOption = undefined;
      });
    },
    initOptionRemove(option: any) {
      this.selectOptionValue = option;
      this.deleteOptionValDialog = true;
    },
    removeOption(option_id: string) {
      this.deleteOptionValue(option_id).then(() => {
        this.deleteOptionValDialog = false;
        this.fetchOptions();
      });
    },
    addOptionValues(item: InventoryOptions) {
      if (item) {
        this.productOption = item;
        this.editOption.option_name = item.option_name;
        this.editOptionDialog = true;
      }
    },
    resetForm() {
      this.editOption = {
        option_name: "",
        option_values: "",
      };
    },
    updateProductOption() {
      if (this.productOption) {
        console.log("updating ...");
        this.updateOption({
          id: this.productOption._id,
          data: this.editOption,
        }).then(() => {
          this.resetForm();
          this.editOptionDialog = false;
          this.productOption = undefined;
          this.fetchOptions();
        });
      }

      if (!this.productOption) {
        this.createOption({
          businessId: (this.role.business as Business)?._id,
          ...this.editOption,
        }).then(() => {
          this.resetForm();
          this.editOptionDialog = false;
          this.fetchOptions();
        });
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("INVENTORY_OPTIONS")) {
      this.$store.registerModule("INVENTORY_OPTIONS", productOptionsStore);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("INVENTORY_OPTIONS");
  },
});
