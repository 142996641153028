

































































import Vue, { PropType } from "vue";

type Image = {
  file: File;
  data: string | ArrayBuffer | null;
};

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  name: "agizo-uploader",
  props: {
    label: String,
    value: [],
    accept: {
      type: String,
      default: "image/png, image/jpeg, image.jpg",
    },
    helpText: {
      type: String,
    },
    max: {
      type: Number,
      default: 4,
    },
    uploaded: {
      type: [] as PropType<any[]>,
      default: [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [] as Image[],
      apiUrl: VUE_APP_API_URL,
    };
  },
  watch: {
    images: {
      handler(val: Image[]) {
        this.$emit(
          "input",
          Object.values(val).map((image: Image) => image.file)
        );
      },
    },
  },
  methods: {
    handleInput(files: File[]) {
      if (files) {
        this.handleFiles(files);
      }
    },
    handleFiles(files: File[]) {
      let selectedFiles: Image[] = this.images;
      [...files].forEach((file) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) {
            selectedFiles.push({ file, data: e.target.result });
          }
        };
        reader.readAsDataURL(file);
      });

      this.images = selectedFiles;
    },
    removeImage(index: number) {
      this.images.splice(index, 1);
      this.$emit(
        "input",
        this.images.map((image) => image.file)
      );
    },
  },
});
