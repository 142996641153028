var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showSupplierDialog),callback:function ($$v) {_vm.showSupplierDialog=$$v},expression:"showSupplierDialog"}},[(_vm.showSupplierDialog)?_c('supplier-form',{on:{"cancel":function($event){_vm.showSupplierDialog = false},"add-category":function($event){_vm.categoryDialog = true},"data-saved":_vm.closeModal}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.categoryDialog),callback:function ($$v) {_vm.categoryDialog=$$v},expression:"categoryDialog"}},[_c('v-form',{ref:"categoryForm",attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateCategory.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Supplier category")]),_c('v-card-text',[_c('v-text-field',{attrs:{"rules":_vm.categoryRules,"label":"Name"},model:{value:(_vm.categoryName),callback:function ($$v) {_vm.categoryName=$$v},expression:"categoryName"}})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.categoryDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Save")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"filters"},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-variant")]),_c('span',[_vm._v("Category: "+_vm._s(_vm.selectedCategory))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((['All']),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.selectedCategory = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-autocomplete',{staticClass:"search-field",attrs:{"items":_vm.supplierPage.docs,"item-text":"fullName","item-value":"_id","height":"20","label":"Search Suppliers"},on:{"change":_vm.viewSupplier},model:{value:(_vm.supplier),callback:function ($$v) {_vm.supplier=$$v},expression:"supplier"}})],1),_c('div',{staticClass:"extra-btns"},[_c('v-btn',{attrs:{"elevation":"0","text":"","small":""},on:{"click":function($event){_vm.showSupplierDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Supplier ")],1),_vm._v("   "),_c('v-btn',{attrs:{"text":"","elevation":"0","small":""}},[_c('v-icon',[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1)],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.supplierPage.docs,"options":_vm.options,"server-items-length":_vm.supplierPage.total,"no-data-text":"No sale products to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[(item.avatar)?_c('img',{attrs:{"src":(_vm.apiUrl + "/v1/file/" + (item.avatar.filename)),"alt":item.fullName}}):_c('img',{attrs:{"src":require("../../assets/placeholder.png"),"alt":item.fullName}}),_vm._v("   ")]),_vm._v(" "+_vm._s(item.fullName)+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.category)?_c('span',[_vm._v(_vm._s(item.category.name))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.viewSupplier(item)}}},[_vm._v(" View ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }