/* eslint-disable @typescript-eslint/no-explicit-any */
import { Page } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

const actions = {
  add_purchase: "ADD_PURCHASE",
  set_purchase_list: "SET_PURCHASE_LIST",
  remove_purchase: "REMOVE_PURCHASE",
};

type PurchaseState = {
  purchasePage: Page<any>;
};

const purchase: Module<PurchaseState, unknown> = {
  namespaced: true,
  state: () => ({
    purchasePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  mutations: {
    [actions.add_purchase](state, _purchase) {
      if (
        state.purchasePage.docs.filter((c) => c._id === _purchase._id).length >
        0
      ) {
        state.purchasePage.docs = state.purchasePage.docs.map((item: any) =>
          item._id === _purchase._id ? _purchase : item
        );
      } else {
        state.purchasePage.docs.push(_purchase);
      }
    },
    [actions.set_purchase_list](state, _purchases) {
      state.purchasePage = _purchases;
    },
    [actions.remove_purchase](state, _purchase) {
      state.purchasePage.docs = state.purchasePage.docs.filter(
        (item) => item._id !== _purchase._id
      );
    },
  },
  getters: {
    purchasePage: (state) => state.purchasePage,
  },
  actions: {
    async createPurchase(context, data) {
      return await api
        .post("/v1/purchase", data)
        .then((response) => {
          context.commit(actions.add_purchase, response.data.purchase);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Purchase Created",
            },
            { root: true }
          );
          return response.data.purchase;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchPurchaseList(context, params = "") {
      return await api
        .get(`/v1/purchase${params}`)
        .then((response) => {
          context.commit(actions.set_purchase_list, response.data.purchasePage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deletePurchase(context, id) {
      return await api
        .delete(`/v1/purchase/${id}`)
        .then((response) => {
          context.commit(actions.remove_purchase, response.data.purchase);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Purchase Deleted",
            },
            { root: true }
          );
          return response.data.purchase;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createPurchaseItem(context, params: { id: string; data: any }) {
      return await api
        .post(`/v1/purchase/add-item/${params.id}`, params.data)
        .then((response) => {
          const purchase = response.data.purchase;
          context.commit(actions.add_purchase, purchase);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Purchase Item Added",
            },
            { root: true }
          );
          return purchase;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deletePurchaseItem(
      context,
      params: { id: string; purchaseId: string }
    ) {
      return await api
        .delete(
          `/v1/purchase/remove-item/${params.id}?purchaseId=${params.purchaseId}`
        )
        .then((response) => {
          const purchase = response.data.purchase;
          context.commit(actions.add_purchase, purchase);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Purchase Item Deleted",
            },
            { root: true }
          );
          return purchase;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async submitPurchase(context, purchaseId: string) {
      return await api
        .put(`/v1/purchase/${purchaseId}/submit`)
        .then((response) => {
          const purchase = response.data.purchase;
          context.commit(actions.add_purchase, purchase);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Purchase Submited",
            },
            { root: true }
          );
          return purchase;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async completePurchase(context, purchaseId: string) {
      return await api
        .get(`/v1/purchase/${purchaseId}/complete`)
        .then((response) => {
          const purchase = response.data.purchase;
          context.commit(actions.add_purchase, purchase);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Purchase Status Updated",
            },
            { root: true }
          );
          return purchase;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default purchase;
