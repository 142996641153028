

































































































import Vue, { VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import supplierStoreModule from "@/store/modules/supplier";
import supplierCategoryStoreModule from "@/store/modules/supplierCategory";
import { Business, Supplier, Role } from "@/types";
import { PHONE_REGEX } from "@/util/constants";

const { mapActions: supplierActions } =
  createNamespacedHelpers("SUPPLIER_FORM");

const {
  mapActions: supplierCategoryActions,
  mapGetters: supplierCategoryGetters,
} = createNamespacedHelpers("SUPPLIER_CATEGORIES");

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField: HTMLInputElement;
      };
    }
  >
).extend<any, any, any, any>({
  name: "SupplierForm",
  data() {
    return {
      valid: false,
      fullName: "",
      phone: "",
      email: "",
      categoryId: "",
      serviceIds: [],
      fullNameRules: [(v: string) => !!v || "Full Name is required"],
      phoneRules: [
        /*(v: string) => !!v || "Phone is required"*/
        (v: string) =>
          PHONE_REGEX.test(v) || "A valid Phone number is required",
      ],
      emailRules: [(v: string) => !!v || "Email is required"],
      categoryRules: [(v: string) => !!v || "Category is required"],
      image: undefined as undefined | File,
    };
  },
  mounted() {
    this.resetForm();
  },
  created() {
    this.fetchSupplierCategoryList();
  },
  computed: {
    ...supplierCategoryGetters(["supplierCategoryPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...supplierActions(["createSupplier"]),
    ...supplierCategoryActions(["fetchSupplierCategoryList"]),
    validateForm() {
      const valid = (
        this.$refs.supplierForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      const formData = new FormData();
      formData.append("businessId", (this.role.business as Business)._id);
      formData.append("fullName", this.fullName);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("categoryId", this.categoryId);
      if (this.image) formData.append("file", this.image as File);
      this.createSupplier(formData).then((supplier: Supplier) => {
        if (supplier) {
          this.$emit("data-saved", supplier);
        }
      });
    },
    resetForm() {
      this.fullName = "";
      this.phone = "";
      this.email = "";
      this.categoryId = "";
      this.image = undefined;
      (
        this.$refs.supplierForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    upload(image: File) {
      this.image = image;
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.image = file;
    },
    searchCategory(q: string) {
      if (q) {
        this.fetchSupplierCategoryList(`?q=${q}`);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SUPPLIER_FORM")) {
      this.$store.registerModule("SUPPLIER_FORM", supplierStoreModule);
    }
    if (!this.$store.hasModule("SUPPLIER_CATEGORIES")) {
      this.$store.registerModule(
        "SUPPLIER_CATEGORIES",
        supplierCategoryStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SUPPLIER_FORM");
    this.$store.unregisterModule("SUPPLIER_CATEGORIES");
  },
});
