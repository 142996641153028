

































































































































































































































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import supplierModule from "@/store/modules/supplier";
import productModule from "@/store/modules/productInventory";
import purchaseModule from "@/store/modules/purchase";
import {
  Business,
  InventoryProduct,
  InventoryVariant,
  PurchaseRequest,
  Role,
} from "@/types";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapGetters: supplierGetters, mapActions: supplierActions } =
  createNamespacedHelpers("SUPPLIER_LIST");
const { mapGetters: productGetters, mapActions: productActions } =
  createNamespacedHelpers("PRODUCT_LIST");
const { mapActions: purchaseActions, mapGetters: purchaseGetters } =
  createNamespacedHelpers("PURCHASE_LIST");

export default Vue.extend<any, any, any, any>({
  name: "PurchaseList",
  data() {
    return {
      addPurchaseDialog: false,
      editPurchaseDialog: false,
      date_menu: false,
      update_date_menu: false,
      purchase: undefined as undefined | PurchaseRequest,
      headers: [
        { text: "Supplier", value: "supplier", sortable: false },
        { text: "Due Date", value: "due", sortable: false },
        { text: "Status", value: "purchase_status", sortable: false },
        { text: "Total", value: "total" },
        { text: "actions", value: "action" },
      ],
      newPurchase: {
        supplierId: "",
        requiredBy: "",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        items: [] as any[],
      },
      newItem: {
        productId: "",
        variantId: "",
        unitPrice: 0,
        quantity: 0,
      },
    };
  },
  created() {
    this.fetchAllData();
  },
  watch: {
    newItem: {
      deep: true,
      handler(val) {
        if (val.productId !== "") {
          const product = Object.values(this.products.docs).filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (product: any) => product._id === val.productId
          )[0] as InventoryProduct;
          if (product.product_type === "simple") {
            this.newItem.unitPrice = product.variants[0].pricing.supply_price;
          }
        }
        if (val.variantId !== "") {
          const variant: any = Object.values(this.variants).filter(
            (v: any) => v._id === val.variantId
          )[0];
          this.newItem.unitPrice = variant ? variant.pricing.supply_price : 0;
        }
      },
    },
    role() {
      this.fetchAllData();
    },
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    ...supplierGetters({ suppliers: "supplierPage" }),
    ...purchaseGetters(["purchasePage"]),
    ...productGetters({ products: "productPage" }),
    variants(): InventoryVariant[] {
      if (this.newItem.productId) {
        const product = Object.values(this.products.docs).filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (product: any) => product._id === this.newItem.productId
        )[0] as InventoryProduct;
        return product.product_type == "variant" ? product.variants : [];
      }
      return [];
    },
  },
  methods: {
    ...supplierActions(["fetchSupplierList"]),
    ...purchaseActions([
      "fetchPurchaseList",
      "createPurchase",
      "deletePurchase",
      "createPurchaseItem",
      "deletePurchaseItem",
      "submitPurchase",
      "completePurchase",
    ]),
    ...productActions(["fetchProductList"]),
    fetchAllData() {
      if (this.role) {
        const business = (this.role.business as Business)._id;
        this.fetchSupplierList(`?businessId=${business}`);
        this.fetchPurchaseList(`?businessId=${business}`);
        this.fetchProductList(`?businessId=${business}`);
      }
    },
    resetNewItemForm() {
      this.newItem = {
        productId: "",
        variantId: "",
        unitPrice: 0,
        quantity: 0,
      };
    },
    addItem() {
      const { productId, variantId, quantity, unitPrice } = this.newItem;
      if (productId && quantity && unitPrice) {
        let title = "";
        const product =
          productId !== ""
            ? (this.products.docs.filter(
                (p: InventoryProduct) => p._id === productId
              )[0] as InventoryProduct)
            : null;
        const variant =
          variantId && this.variants.length > 0
            ? (this.variants.filter(
                (v) => v._id === variantId
              )[0] as InventoryVariant)
            : null;
        const variant_title = variant
          ? variant.options
              .map(
                (opt) =>
                  opt.option_name_id.option_name +
                  ":" +
                  opt.option_value_id.value
              )
              .join(",")
          : "";
        if (product) {
          title = `${product.title}${variant ? "- " + variant_title : ""}`;
        }
        this.newPurchase.items.push({
          productId,
          variantId,
          quantity,
          unitPrice,
          productName: title,
        });
        this.resetNewItemForm();
      }
    },
    removeItem(index: number) {
      this.newPurchase.items.splice(index, 1);
    },
    addPurchaseItem() {
      if (this.purchase) {
        const { productId, variantId, quantity, unitPrice } = this.newItem;
        let title = "";
        const product =
          productId !== ""
            ? (this.products.docs.filter(
                (p: InventoryProduct) => p._id === productId
              )[0] as InventoryProduct)
            : null;
        const variant =
          variantId && this.variants.length > 0
            ? (this.variants.filter(
                (v) => v._id === variantId
              )[0] as InventoryVariant)
            : null;
        const variant_title = variant
          ? variant.options
              .map(
                (opt) =>
                  opt.option_name_id.option_name +
                  ":" +
                  opt.option_value_id.value
              )
              .join(",")
          : "";
        if (product) {
          title = `${product.title}${variant ? "- " + variant_title : ""}`;
        }
        this.createPurchaseItem({
          id: this.purchase._id,
          data: {
            productId,
            variantId,
            quantity,
            unitPrice,
            productName: title,
          },
        }).then((item) => {
          if (item && this.purchase) {
            this.purchase.items.push({
              productId,
              variantId,
              quantity,
              unitPrice,
              productName: title,
            });
          }
        });

        this.resetNewItemForm();
      }
    },
    removePurchaseItem(index: number, itemId: string) {
      if (this.purchase) {
        this.deletePurchaseItem({
          id: itemId,
          purchaseId: this.purchase._id,
        }).then((item) => {
          if (item) {
            this.purchase?.items.splice(index, 1);
          }
        });
      }
    },
    createRequest() {
      const business = (this.role.business as Business)._id;
      this.createPurchase({ businessId: business, ...this.newPurchase }).then(
        (purchase) => {
          if (purchase) {
            this.cancelAdd();
          }
        }
      );
    },
    format_date(value: Date) {
      if (value) {
        return moment(String(value)).tz(tz).format("DD,MMMM,YYYY");
      }
    },
    resetAddForm() {
      this.newPurchase = {
        supplierId: "",
        items: [],
        requiredBy: "",
      };
    },
    cancelAdd() {
      this.resetAddForm();
      this.addPurchaseDialog = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editPurchase(purchase: any) {
      this.purchase = purchase;
      this.editPurchaseDialog = true;
    },
    submitPurchaseToSupplier(purchase_id: string) {
      this.submitPurchase(purchase_id).then(() => {
        this.fetchAllData();
        this.editPurchaseDialog = false;
      });
    },
    receivePurchase(purchase_id: string) {
      this.completePurchase(purchase_id).then(() => {
        this.fetchAllData();
        this.editPurchaseDialog = false;
      });
    },
    getOptions(options: any) {
      return Object.values(options)
        .map((option: any) => {
          return (
            option.option_name_id.option_name +
            "-" +
            option.option_value_id.value
          );
        })
        .join(",");
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SUPPLIER_LIST")) {
      this.$store.registerModule("SUPPLIER_LIST", supplierModule);
    }
    if (!this.$store.hasModule("PRODUCT_LIST")) {
      this.$store.registerModule("PRODUCT_LIST", productModule);
    }
    if (!this.$store.hasModule("PURCHASE_LIST")) {
      this.$store.registerModule("PURCHASE_LIST", purchaseModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SUPPLIER_LIST");
    this.$store.unregisterModule("PRODUCT_LIST");
    this.$store.unregisterModule("PURCHASE_LIST");
  },
});
