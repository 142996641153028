import { InventoryOptions } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

const actions = {
  add_option: "ADD_PRODUCT_OPTION",
  set_optionlist: "SET_PRODUCT_OPTION_LIST",
  remove_option: "REMOVE_PRODUCT_OPTION",
};

type ProductOptionState = {
  productOptions: InventoryOptions[];
};

const productOptions: Module<ProductOptionState, unknown> = {
  namespaced: true,
  state: () => ({
    productOptions: [],
  }),
  mutations: {
    [actions.add_option](state, _option) {
      if (
        state.productOptions.filter((item: any) => item._id === _option._id)
          .length > 0
      ) {
        state.productOptions = state.productOptions.map(
          (item: any, index: number) =>
            item._id === _option._id ? _option : item
        );
      } else {
        state.productOptions.push(_option);
      }
    },
    [actions.set_optionlist](state, _options) {
      state.productOptions = _options;
    },
    [actions.remove_option](state, _option) {
      state.productOptions = state.productOptions.filter(
        (item) => item._id !== _option._id
      );
    },
  },
  getters: {
    productOptions: (state) => state.productOptions,
  },
  actions: {
    async createOption(context, payload) {
      return await api
        .post("/v1/product/options", payload)
        .then((response) => {
          context.commit(actions.add_option, response.data.option);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Option Created",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchOptions(context, params = "") {
      return await api
        .get(`/v1/product/options${params}`)
        .then((response) => {
          context.commit(actions.set_optionlist, response.data.options);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateOption(
      context,
      payload: {
        id: string;
        data: { option_name: string; option_values: string };
      }
    ) {
      return await api
        .put(`/v1/product/options/${payload.id}`, payload.data)
        .then((response) => {
          context.commit(actions.add_option, response.data.option);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Option Updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteOption(context, id: string) {
      return await api
        .delete(`/v1/product/options/${id}`)
        .then((response) => {
          context.commit(actions.remove_option, response.data.option);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Option Deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteOptionValue(context, id: string) {
      return await api
        .delete(`/v1/product/options/value/${id}`)
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Option Value Deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default productOptions;
