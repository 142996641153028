






































































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import saleProductStoreModule from "@/store/modules/saleProduct";
import {
  Business,
  InventoryCategory,
  InventoryProduct,
  Role,
  SaleProduct,
} from "@/types";

import productInventoryModule from "@/store/modules/productInventory";
import productCategoryModule from "@/store/modules/productCategory";

const { mapActions: saleProductActions, mapGetters: saleProductGetters } =
  createNamespacedHelpers("SALE_PRODUCT_LIST");

const {
  mapGetters: inventoryProductGetters,
  mapActions: inventoryProductActions,
} = createNamespacedHelpers("INVENTORY_PRODUCT");

const {
  mapActions: inventoryCategoryActions,
  mapGetters: inventoryCategoryGetters,
} = createNamespacedHelpers("INVENTORY_CATs");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: {},
  name: "SaleProductList",
  data: () => ({
    saleProduct: undefined as undefined | InventoryProduct,
    product_usage_options: [
      { text: "ALL", value: "all" },
      { text: "Sale Products", value: "sale" },
      { text: "In Store Products", value: "in_store" },
    ],
    product_stock_options: [
      { text: "ALL", value: "all" },
      { text: "In Stock", value: "in_stock" },
      { text: "Out Of Stock", value: "out_stock" },
    ],
    filters: {
      usage: "all",
      search: "",
      stock: "all",
      category: "",
    },
    showDialog: false,
    addDialog: false,
    editProductDialog: false,
    deleteProductDilaog: false,
    apiUrl: VUE_APP_API_URL,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "title",
      },
      { text: "Type", value: "product_type" },
      { text: "Brand", value: "brand" },
      { text: "Category", value: "category.category_name" },
      { text: "Usage", value: "usage", sortable: false },
      { text: "Stock", value: "stock" },
      { text: "Weight", value: "weight" },
      { text: "Actions", value: "action" },
    ],
    options: {} as { page: number },
    exporting: false,
  }),
  watch: {
    role() {
      this.fetchProducts();
    },
    options: {
      handler() {
        this.fetchProducts();
      },
      deep: true,
    },
  },
  computed: {
    ...saleProductGetters(["saleProductPage"]),
    ...inventoryProductGetters(["productPage"]),
    ...inventoryCategoryGetters(["categoryPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    categories(): InventoryCategory[] {
      return this.categoryPage?.docs || [];
    },
    filteredData: function (): InventoryProduct[] {
      const results = this.productPage.docs
        .filter((product: InventoryProduct) => {
          if (this.filters.search) {
            return (
              product.title.match(new RegExp(this.filters.search, "i")) ||
              product.short_description.match(
                new RegExp(this.filters.search, "i")
              )
            );
          } else {
            return true;
          }
        })
        .filter((product: InventoryProduct) => {
          if (this.filters.usage == "all") {
            return true;
          }

          if (this.filters.usage == "sale") {
            return product.in_store == false;
          }

          // if (this.filters.usage == "in_store") {
          //   return product.in_store == true;
          // }
        })
        .filter((product: InventoryProduct) => {
          if (this.filters.stock == "all") {
            return true;
          }

          if (this.filters.stock == "in_stock") {
            return product.isAvailable == true;
          }

          if (this.filters.stock == "out_stock") {
            return product.isAvailable == false;
          }
        })
        .filter((product: InventoryProduct) => {
          const { category } = this.filters;
          if (category) {
            const thisCategory = this.categories.filter(
              (c) => c.category_name == category
            )[0];
            const subCategories = (
              thisCategory.children as InventoryCategory[]
            ).map((c) => c._id);
            return (
              (product.category as unknown as InventoryCategory)?._id ==
                thisCategory?._id ||
              subCategories.includes(
                (product.category as unknown as InventoryCategory)?._id
              )
            );
          }

          return true;
        });
      return results;
    },
  },
  created() {
    this.fetchProducts();
  },
  methods: {
    ...saleProductActions(["updateSaleProduct", "deleteSaleProduct"]),
    ...inventoryProductActions([
      "fetchProductList",
      "dropProduct",
      "exportInventory",
    ]),
    ...inventoryCategoryActions(["fetchCategoryList"]),
    fetchProducts() {
      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)._id
        }&page=${this.options.page || 1}`;
        this.fetchProductList(params + `&inStore=${false}`);
        this.fetchCategoryList(params);
      }
    },
    updateProduct(newProduct: SaleProduct) {
      this.updateSaleProduct({ id: newProduct._id, ...newProduct }).then(() => {
        this.editProductDialog = false;
      });
    },
    deleteConfirm(item: InventoryProduct) {
      if (item) {
        this.saleProduct = item;
        this.deleteProductDilaog = true;
      }
    },
    deleteProduct(productId: string) {
      this.dropProduct(productId).then(() => {
        this.deleteProductDilaog = false;
      });
    },
    closeModal() {
      this.fetchProducts();
      this.showDialog = false;
    },
    exportProducts() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)._id}`;
        this.exporting = true;
        this.exportInventory(params).then((res) => {
          this.exporting = false;
          if (res.data) {
            const fileURL = window.URL.createObjectURL(new Blob([res.data]));
            const fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "inventory.csv");
            document.body.appendChild(fileLink);

            fileLink.click();
          }
        });
      }
    },
    selectItem(item: string) {
      this.filters.usage = item;
    },
    selectStock(item: string) {
      this.filters.stock = item;
    },
    selectCategory(category: string) {
      this.filters.category = category;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SALE_PRODUCT_LIST")) {
      this.$store.registerModule("SALE_PRODUCT_LIST", saleProductStoreModule);
    }
    if (!this.$store.hasModule("INVENTORY_PRODUCT")) {
      this.$store.registerModule("INVENTORY_PRODUCT", productInventoryModule);
    }

    if (!this.$store.hasModule("INVENTORY_CATs")) {
      this.$store.registerModule("INVENTORY_CATs", productCategoryModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SALE_PRODUCT_LIST");
    this.$store.unregisterModule("INVENTORY_PRODUCT");
    this.$store.unregisterModule("INVENTORY_CATs");
  },
});
