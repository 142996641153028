var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-toolbar',{staticClass:"mt-2 flex-wrap-reverse",attrs:{"flat":"","dense":""}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-menu',{attrs:{"oofset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":"","tile":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-variant")]),_c('span',[_vm._v("Filters")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,3089165416)},[_c('v-list',[_c('v-list-item',[_c('v-select',{attrs:{"prefix":"Usage","items":_vm.product_usage_options,"item-text":"text","item-value":"value"},model:{value:(_vm.filters.usage),callback:function ($$v) {_vm.$set(_vm.filters, "usage", $$v)},expression:"filters.usage"}})],1),_c('v-list-item',[_c('v-select',{attrs:{"prefix":"Stock","items":_vm.product_stock_options,"item-text":"text","item-value":"value"},model:{value:(_vm.filters.stock),callback:function ($$v) {_vm.$set(_vm.filters, "stock", $$v)},expression:"filters.stock"}})],1),_c('v-list-item',[_c('v-select',{attrs:{"prefix":"Category","items":_vm.categories,"item-text":"category_name","item-value":"category_name","clearable":"","dense":""},model:{value:(_vm.filters.category),callback:function ($$v) {_vm.$set(_vm.filters, "category", $$v)},expression:"filters.category"}})],1),_c('v-list-item',[_c('v-text-field',{attrs:{"placeholder":"Search","clearable":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?[(false)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":"","tile":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-variant")]),_c('span',[_vm._v("Usage: "+_vm._s(_vm.filters.usage))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,4181716506)},[_c('v-list',_vm._l((_vm.product_usage_options),function(item){return _c('v-list-item',{key:item.value,on:{"click":function($event){return _vm.selectItem(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":"","tile":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-variant")]),_c('span',[_vm._v("Stock: "+_vm._s(_vm.filters.stock))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,2102613082)},[_c('v-list',_vm._l((_vm.product_stock_options),function(item){return _c('v-list-item',{key:item.value,on:{"click":function($event){return _vm.selectStock(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":"","tile":"","small":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Category: "+_vm._s(_vm.filters.category || "all"))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,3691809423)},[_c('v-list',_vm._l((_vm.categories),function(item){return _c('v-list-item',{key:item._id,on:{"click":function($event){return _vm.selectCategory(item.category_name)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.category_name))])],1)}),1)],1),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.search),expression:"filters.search"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.filters.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "search", $event.target.value)}}})])]:_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"to":'/products/create?inStore=false',"elevation":"0","text":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Product ")],1),_c('v-btn',{attrs:{"disabled":_vm.exporting,"elevation":"0","text":"","small":""},on:{"click":_vm.exportProducts}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1)],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredData,"options":_vm.options,"server-items-length":_vm.productPage.total,"no-data-text":"No sale products to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[(item && item.images && item.images.length > 0)?_c('img',{attrs:{"src":(_vm.apiUrl + "/v1/file/" + (item.images[0].filename)),"alt":item.title}}):_c('img',{attrs:{"src":require("../../assets/placeholder.png"),"alt":item.name}}),_vm._v("   ")]),_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [(
          item.attributes.filter(function (b) { return b.attr_name == 'brand'; }).length > 0
        )?_c('span',[_vm._v(" "+_vm._s(((item.attributes.filter(function (b) { return b.attr_name == "brand"; })[0] .attr_value) + " "))+" ")]):_c('span',[_vm._v("No Brand")])]}},{key:"item.weight",fn:function(ref){
        var item = ref.item;
return [(
          item.attributes.filter(function (b) { return b.attr_name == 'weight'; }).length > 0
        )?_c('span',[_vm._v(" "+_vm._s(((item.attributes.filter(function (b) { return b.attr_name == "weight"; })[0] .attr_value) + " Kg"))+" ")]):_c('span',[_vm._v("N/A")])]}},{key:"item.usage",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.in_store ? "In store" : "Sale Product"))])]}},{key:"item.stock",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:{
          'red--text': !item.isAvailable,
          'primary--text': item.isAvailable,
        }},[_vm._v(" "+_vm._s(item.isAvailable ? "In Stock" : "Out of Stock")+" ")])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"to":("/products/edit/" + (item._id)),"icon":""}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteConfirm(item)}}},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)}),(_vm.saleProduct)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.deleteProductDilaog),callback:function ($$v) {_vm.deleteProductDilaog=$$v},expression:"deleteProductDilaog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Confirm")]),_c('v-card-text',[_c('p',[_vm._v("Are you sure to delete this product?")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.deleteProductDilaog = false}}},[_vm._v("cancel")]),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.deleteProduct(_vm.saleProduct._id)}}},[_vm._v("Delete")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }